<template>
  <div>
    <header class="bg-gray-50 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
      <div class="flex flex-wrap items-center justify-between mx-6 my-4">
        <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
          Events
        </h2>
        <div class="block pb-1 mt-2">
          <!-- <v-btn text class="subsecondary ml-2" color="secondary rounded-lg">
                        <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                        <span>Mew post</span>
                    </v-btn> -->
        </div>
      </div>
    </header>
    <section class="relative bg-gray-50 mt-4 pa-6 pb-20 lg:pa-8 lg:pb-28 max-h-hev">
      <div class="page-title text-center px-5">
        <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
          <span class="me-2">No event yet</span>
          <v-icon color="warning">
            {{ icons.mdiAlert }}
          </v-icon>
        </h2>
        <p class="text-sm">
          The page event will comming soon..
        </p>

        <div class="misc-character d-flex justify-center">
          <v-img max-width="700" src="@/assets/images/pages/cuve-line.svg"></v-img>
        </div>

        <v-btn color="primary" :to="{ name: 'user.home' }" class="mb-4">
          Back to home
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
